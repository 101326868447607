var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "back",
      style: {
        background:
          "url(" +
          require("@/assets/images/login_success.gif") +
          ")" +
          "no-repeat"
      }
    },
    [
      _c("img", {
        staticClass: "logo_zhineng",
        attrs: { src: require("@/assets/images/zhineng_logo.png"), alt: "" }
      }),
      _c("div", { staticClass: "tip" }, [_vm._v("智能化多媒体管理和生产系统")]),
      _c(
        "div",
        { staticClass: "userinfo" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.emailtitle))]),
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.emailinfo))]),
          _c("div", { staticClass: "text1" }, [_vm._v(_vm._s(_vm.email))]),
          _vm.showbtn == 0
            ? _c(
                "el-button",
                {
                  staticClass: "test",
                  attrs: { type: "primary" },
                  on: { click: _vm.login }
                },
                [_vm._v("登录")]
              )
            : _vm._e(),
          _vm.showbtn == 1
            ? _c(
                "el-button",
                {
                  staticClass: "test",
                  attrs: { type: "primary" },
                  on: { click: _vm.register }
                },
                [_vm._v("注册")]
              )
            : _vm._e(),
          _vm.showbtn == 1
            ? _c("div", { staticClass: "bottom", on: { click: _vm.login } }, [
                _vm._v("已有账号，去登录")
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }