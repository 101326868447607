<template>
  <!-- 个人信息页背景 -->
  <div
    class="back"
    :style="{
      background:
        'url(' +
        require('@/assets/images/login_success.gif') +
        ')' +
        'no-repeat',
    }"
  >
    <!-- logo -->
    <!-- <img class="logo" src="@/assets/images/login-logo.png" alt="" /> -->
    <img class="logo_zhineng" src="@/assets/images/zhineng_logo.png" alt="" />
    <!-- 标题 -->
    <div class="tip">智能化多媒体管理和生产系统</div>
    <!-- 个人信息容器 -->
    <div class="userinfo">
      <!-- 标题 -->
      <div class="title">{{emailtitle}}</div>
      <div class="text">{{emailinfo}}</div>
      <div class="text1">{{ email }}</div>
      <el-button class="test" v-if="showbtn==0" type="primary" @click="login">登录</el-button>
      <el-button class="test" v-if="showbtn==1" type="primary" @click="register">注册</el-button>
      <div class="bottom" v-if="showbtn==1" @click="login">已有账号，去登录</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        // 邮箱
        email:'',
        // 账号状态
        emailtitle:'',
        emailinfo:'',
        // 登录注册按钮切换
        showbtn:''

    };
  },
  methods: {
    login(){
      this.$router.push('/login')
    },
    register(){
      this.$router.push('/register')
    }
  },
  created(){
    //注册成功或失败状态
      this.$http({
            method: "GET",
            url: "/accounts/email_activate/",
            params: {email:this.$route.query.email,code:this.$route.query.code},
          }).then((res) => {
            // 账号激活成功
            if(res.data.status==0){
                this.emailtitle='注册成功',
                this.email=this.$route.query.email
                this.emailinfo='您可使用下面邮箱进行登录'
                this.showbtn=0
            }
            // 链接失效
             if(res.data.status==1){
               this.emailtitle='注册失败';
               this.emailinfo='链接已过期，请重新注册。'
                this.showbtn=1
            }
          }).catch(error => console.log(error))

  }
};
</script>

<style lang="scss" scoped >
// 个人信息页背景
.back {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  //logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
  }
  .logo_zhineng{
    width: 13vw;
    margin: 12vh auto 1vh auto;
  }
  //   标题
  .tip {
    font-size: 1.6vw;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    opacity: 1;
  }
  //   个人信息容器
  .userinfo {
    margin-top: 6.41vh;
    width: 532px;
    height: 446px;
    background: rgba(57, 56, 70, 0.6);
    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
      margin-top: 111px;
      margin-left: 126px;
      height:21px;
    }
    .text {
      margin-top: 27px;
      margin-left: 126px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
    }
    .text1 {
      margin-left: 126px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      opacity: 0.6;
    }
    .el-button--primary {
      width: 280px;
      height: 40px;
      margin-left: 126px;
      margin-top: 64px;
    }
    .bottom{
      margin-top:20px;
      margin-left:290px;
      cursor: pointer;
    }
    .test{
      /deep/span{
          padding-left:30px;
        letter-spacing: 30px;
      }
    }
  }
}
</style>

